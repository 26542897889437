import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import s from './TextBlock.module.css'

function TextBlock({ text, useTermsStyling, globalTextColour }) {
  return (
    <Fade cascade bottom ssrReveal duration={500} distance="20px">
      <div
        className={
          useTermsStyling && useTermsStyling[0] === 'yes'
            ? s.containerTerms
            : s.container
        }
      >
        <div
          className={
            useTermsStyling && useTermsStyling[0] === 'yes'
              ? s.contentTerms
              : s.content
          }
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ color: globalTextColour ? globalTextColour : 'initial' }}
        />
      </div>
    </Fade>
  )
}

TextBlock.propTypes = {
  text: PropTypes.string,
  globalTextColour: PropTypes.string,
}
TextBlock.defaultProps = {
  text: '',
  globalTextColour: '',
}

export default TextBlock
